<template>
  <div class="container" v-html="content"></div>
</template>
<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  created() {
      console.log( this.$route.query.data)
    this.content = JSON.parse(this.$route.query.data);
  },
};
</script>